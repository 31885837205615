import { createApp } from 'vue'
import { http } from './_axios.js';
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue-3';
import VueDatePicker from 'vue3-datepicker';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import '@/assets/styles/bootstrap-custom.scss';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';


const app = createApp(App);

app.config.globalProperties.http = http;

library.add(fas, fab, far);

app
    .use(store)
    .use(router)
    .use(BootstrapVue)
    .use(VueSweetalert2)
    .use(ToastPlugin)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('VueDatePicker', VueDatePicker)
    .mount('#app')
