<script>
export default {
  name: 'CommonHeader',

}
</script>

<template>
  <div>
    <b-nav tabs class="bg-secondary-subtle mt-1 border-start border-top border-end rounded-top">
      <b-nav-item class="active">경기 일정</b-nav-item>
      <b-nav-item >기록실</b-nav-item>
      <b-nav-item class="text-black">회원리스트</b-nav-item>
    </b-nav>
  </div>
</template>

<style scoped>
.active {
  color: black;
  background-color: white;
  font-weight: bold;
}
</style>