<script>
import {useRoute} from 'vue-router';
import CommonHeader from "@/components/common/CommonHeader.vue";

export default {
  components: {CommonHeader},
  setup() {
    const route = useRoute();
    return {
      currentPath: route.name
    }
  },
}
</script>

<template>
  <b-container>
    <div class="mt-2 border shadow-sm rounded">
      <router-view/>
    </div>
  </b-container>
  <footer class="bg-black text-white fixed-bottom">
    <div class="align-middle">
      <ul class="list-unstyled d-flex justify-content-center mb-0 pt-1 pb-1">
        <li class="fs-3">
          <router-link :to="{ path: '/games/attend' }" class="text-white">
            <font-awesome-icon :icon="['far', 'bell']"/>
          </router-link>
        </li>
      </ul>
    </div>
  </footer>

</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
