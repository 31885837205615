import { createRouter, createWebHistory } from 'vue-router'
import ToastPlugin from 'vue-toast-notification';
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '@/components/GameSchedule.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Login.vue')
  },
  {
    path: '/schedules/add',
    name: 'ScheduleAdd',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Schedule.vue')
  },
  {
    path: '/games/attend',
    name: 'Attendance',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Attendance.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !localStorage.getItem("user")) {
    return next({ name: "Login" });
  }
  return next();
})


export default router
