import axios from 'axios';

//const baseURL = 'http://localhost:8088/api';
const baseURL = 'https://ares-fc.run/api';

const http = axios.create({
  baseURL: baseURL,
  timeout: 6000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
});

http.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  return config;
});

export { http };
